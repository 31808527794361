<template>
  <div class="popup-holder popup-import" :style="[showDemoPopup ? '' : 'display:none']">
    <div class="popup-wrap  demo-popup">
      <div class="popup-head">
        <!-- <h2>AudioScribe.io Demo</h2> -->
        <a @click="closeDemoPopup" href="#" class="popup-close icon-close"></a>
      </div>
      <div class="popup-body" style="padding:0px;">
        <div class="">
          <div
            style="position: relative; box-sizing: content-box; max-height: 100vh; max-height: 100svh; width: 100%; aspect-ratio: 1.9178690344062153; padding: 40px 0 40px 0;">
            <iframe :src="computedIframeUrl" loading="lazy" title="Audioscribe.io Demo" allow="clipboard-write"
              frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
          </div>
        </div>
      </div>
      <!-- <div class="popup-foot">
        <div class="import-counts">10 of 10 imports left</div>
        <div class="btn-holder">
          <a href="#" class="btn btn-secondary-outline"
            >Upgrade to Business for unlimited imports</a
          >
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { createTempAudioFile } from "@/services/workspace.service";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

export default {
  name: "DemoPopup",
  props: ["showDemoPopup"],
  computed: {
    ...mapStores(useAppStore),
    ...mapStores(useWorkSpaceStore),
    computedIframeUrl() {
      const email = this.appStore?.user?.email || "";
      return `https://app.supademo.com/demo/clzosqmrq098tgumtmz4zlsco?v_email=${encodeURIComponent(email)}`;
    }
  },
  data() {
    return {
      greeting: "Hello from parent",
    };
  },
  methods: {
    closeDemoPopup: function () {
      console.log("toggleDemoPopup");
      this.appStore.showDemoPopup = false;
    },
  },
};
</script>

<style>
.demo-popup {
  max-width: 700px;
}
</style>
